<template lang="pug">
.main-wrapper.caixas-listar
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / <b>Contas a Receber</b>

    Dialog.dialogCancelar(header='Cancelar Parcela' :visible.sync='dialogCancelar.visible' :modal='true')
        .ta-center
            p Deseja cancelar a parcela  <b> {{dialogCancelar.data?.nr_parcela}} </b>?
        .ta-center.my-4
            ProgressBar(v-if='waitingCancelarParcela' mode='indeterminate' strokeWidth='6')
            Button.p-button-danger(v-else label='Cancelar' @click='cancelarParcela()')
    
    Dialog.dialogAdicionarBaixar(header="Baixa de Fatura" :modal='true' :visible.sync='dialogBaixaFatura.visible')
        .p-grid.p-fluid
            .p-col-6()
                label.form-label Data de Recebimento:
                .p-inputgroup
                    Calendar(appendTo='body' dateFormat="dd/mm/yy" :locale="ptbr" v-model='baixaFaturaModel.dt_baixa'
                        :manualInput='false' placeholder='DD/MM/YYYY')
            .p-col-6
            .p-col-3
                label.form-label Valor Total:
                InputText(disabled v-model='baixaFaturaModel.vl_baixa')
            .p-col-3
                label.form-label Valor Pago:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_pago')
            .p-col-3
                label.form-label Desconto:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_desconto')
            .p-col-3
                label.form-label Acréscimo:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_acrescimo')
            .p-col-3
                label.form-label Juros:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_juros')
            .p-col-3
                label.form-label Multas:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_multa')
            .p-col-3
                label.form-label IR:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_ir')
            .p-col-3
                label.form-label ISS:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaFaturaModel.vl_iss')
            .p-col-12
                Button(v-if='!waitingBaixarParcela' label='Salvar' @click='baixarFatura()')
                ProgressBar(v-if='waitingBaixarParcela' mode="indeterminate")
    

    Dialog.dialogAdicionarBaixar(header="Baixa de Conta a Receber" :modal='true' :visible.sync='dialogBaixa.visible')
        .p-grid.p-fluid
            .p-col-6()
                label.form-label Data de Recebimento:
                .p-inputgroup
                    Calendar( dateFormat="dd/mm/yy" :locale="ptbr" v-model='baixaModel.dt_baixa'
                        :manualInput='false' placeholder='DD/MM/YYYY')
            .p-col-6
            .p-col-3
                label.form-label Desconto:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_desconto')
            .p-col-3
                label.form-label Juros:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_juros')
            .p-col-3
                label.form-label Multas:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_multa')
            .p-col-3
                label.form-label Impostos totais:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='baixaModel.vl_impostos_totais')

            .p-col-12
                Panel
                    template(#header)
                        .p-grid.p-fluid(style='margin-top: 1px')
                            .p-col-6
                                span  <b>Formas de Pagamento</b>
                            .p-col-6.ta-right
                                span <b>Total: {{ dialogBaixa.data.vl_titulo_f }} </b>
                    .p-grid.p-fluid
                        .p-col-3(v-if='formasPagamentoList.length' v-for='elem, idx in formasPagamentoList' :key='idx')
                            Card.payment-box
                                template(slot='content')
                                    .ta-right
                                        a.btn-remove-payment(href='javascript:;' @click='formasPagamentoList.splice(idx, 1);')
                                            i.jam.jam-close
                                    .ta-center
                                        div.icon-box(v-if='elem.cd_forma_pagamento == 1')
                                            i.pi.pi-money-bill
                                        div.icon-box(v-if='elem.cd_forma_pagamento == 3')
                                            i.jam.jam-coin
                                        div.icon-box(v-if='elem.cd_forma_pagamento == 4')
                                            i.jam.jam-phone
                                        div.icon-box(v-if='elem.cd_forma_pagamento == 5')
                                            i.jam.jam-credit-card
                                        div.icon-box(v-if='elem.cd_forma_pagamento == 6')
                                            i.jam.jam-id-card
                                        p.text-method {{ elem.cd_forma_pagamento_f }}
                                        p.text-value {{ elem.vl_pago_f }}
                                        p.text-method(style='font-size: 12px !important') <b>Destino: </b>
                                            span {{ elem.cd_conta_recebimento_f }}

                        .p-col-3(@click='handleFormaPagamentoDialog()' v-if='resta != 0')
                            Card.payment-box(style='cursor: pointer')
                                template(slot='content')
                                    .ta-center
                                        div.icon-box
                                            i.jam.jam-plus
                                        p.text-method Adicionar forma de pagamento
            .p-col-12.p-grid.p-fluid
                .p-col-3
                .p-col-3
                .p-col-3
                .p-col-3.ta-right(v-if='!resta == 0')
                    span.info-resta (Resta {{ $root.formatPrice(resta) }})
            .p-col-12
                ProgressBar(v-if="waitingComprovanteBaixa" mode="indeterminate")
                .p-inputgroup(v-else)
                    .p-input-icon-left.p-input-icon-right
                        InputText(type="text" placeholder="Anexar comprovante" v-model="comprovanteBaixa.name"
                            @click="$refs.comprovanteBaixa.click()" readonly)
                        i.jam.jam-attachment(@click="$refs.comprovanteBaixa.click()" v-tooltip.top="'Anexar'")
                    Button.p-button-success(
                        style="margin-right: flex-end;"
                        icon="pi pi-plus"
                        v-tooltip.top="'Adicionar comprovante'"
                        @click="$refs.comprovanteBaixa.click()")
                input(
                    v-show='false'
                    type="file"
                    ref="comprovanteBaixa"
                    @change="anexarComprovanteBaixa()"
                    accept=".pdf, image/png, image/jpeg")
            .p-col-12
                Button(v-if='!waitingBaixarParcela' label='Salvar' @click='baixarParcela()')
                ProgressBar(v-if='waitingBaixarParcela' mode="indeterminate")

    Dialog.dialogAdicionar(header='Adicionar forma de pagamento' :modal='true' :visible.sync='dialog.formaPagamento')
        .p-grid.p-fluid
            .p-col-6
                label.form-label Método:
                Dropdown(
                    v-model='formaPagamentoModel.cd_forma_pagamento'
                    :options='options.formasPagamento'
                    optionLabel='label'
                    optionValue='value'
                    dataKey='value'
                )
            .p-col-6
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon R$
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        locale='pt-BR' :auto-decimal-mode='true' v-model='formaPagamentoModel.vl_pago')
            .p-col-12
                label.form-label Conta de Recebimento:
                Dropdown( :options='options.contas' v-model='formaPagamentoModel.cd_conta_recebimento'
                    dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione')
            .p-col-12.mt-2.ta-center
                Button(label='Adicionar' icon="jam jam-plus" type="button" @click='adicionarFormaPagamento()')

    TabView.mt-2(@tab-change='onTabChange')
        TabPanel(header="Contas a Receber" v-bind:telaVisivel="'CR'" :active="telaVisivel === 'CR'" @click='telaVisivel = "CR"')
        TabPanel(header="Faturas" v-bind:telaVisivel="'FA'" :active="telaVisivel === 'FA'" @click='telaVisivel = "FA"')
        div(v-if="telaVisivel == 'CR'")
            Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
                .p-grid.p-fluid
                    .p-col-12.p-md-3
                        label.form-label Credor:
                        .p-inputgroup
                            Dropdown(
                                placeholder='Selecione o credor...'
                                :options='options.tipoOption'
                                optionLabel='label'
                                optionValue='value'
                                @change='() => { applyFilters();}'
                                v-model='filters.ie_tipo'
                                filter
                            )

                    .p-col-12.p-md-3
                        label.form-label Situação:
                        ProgressBar(v-if='loading.situacao' mode="indeterminate")
                        .p-inputgroup(v-else)
                            Dropdown(
                                placeholder='Selecione situação...'
                                :options='options.situacao'
                                optionLabel='label'
                                optionValue='value'
                                @change='applyFilters()'
                                v-model='filters.situacao'
                                filter
                            )
                    .p-col-12.p-md-3
                        label.form-label Data Inicial:
                        .p-inputgroup
                            Calendar( v-model='filters.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                            Button(icon='jam jam-rubber' @click="() => {filters.dt_inicial = null; applyFilters();}")

                    .p-col-12.p-md-3
                        label.form-label Data Final:
                        .p-inputgroup
                            Calendar( v-model='filters.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                            Button(icon='jam jam-rubber' @click="() => {filters.dt_final = null; applyFilters();}")

                    .p-col-3.p-md-3.ta-right
                        Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')
            .p-grid
                .p-col-9.p-grid.my-2.p-fluid
                    .info-container
                        span(style='color: #F42C2C; padding: 5px; font-size: 12px') Contas Vencidas: {{ formatPrice(info.contas_vencidas) }}
                    .info-container
                        span(style='color: #E07D09 ; padding: 5px; font-size: 12px') Contas a vencer: {{ formatPrice(info.contas_vencer) }}
                    .info-container
                        span(style='color: #48BA53 ; padding: 5p; font-size: 12px') Contas Recebidas: {{ formatPrice(info.contas_pagas) }}
                    .info-container
                        span(style='padding: 5p; font-size: 12px') Contas Total: {{ formatPrice(info.total) }}
                    i(class='jam jam-info' style='color: #187EF6' v-tooltip.top="'Valores calculados com base nos valores após deduções'")
                .p-col-3.ta-right.my-2
                    Button(label='Lançamento Contas a Receber' icon="jam jam-plus" @click="$router.push('/contas-receber/salvar/')")


            ProgressBar(v-if='waiting' mode="indeterminate")
            div(v-else-if='list.length == 0')
                p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
            div(v-else)
                DataView.dataview.my-2(:value="list" layout="grid")
                    template(#grid="props")
                        .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                            Panel.panel-list.ta-center(:header="props.data.nm_centro_custo" style='position: relative')
                                .ta-left
                                    p <b>Tipo:</b> {{ dominio.tipo[props.data.ie_tipo] || props.data.ie_tipo}}
                                .ta-left
                                    p <b>Origem:</b> {{ props.data.nm_origem }}
                                .ta-left
                                    p <b>Destino:</b> {{ props.data.nm_destino }}
                                .ta-left
                                    p <b>Valor:</b> {{formatPrice(props.data.nr_valor)}}
                                .ta-left
                                    p <b>Data:</b> {{ props.data.dt_transferencia_f }}
                                .ta-left
                                    p <b>Conta Financeira:</b> {{ props.data.nm_plano_contas }}
                                .ta-right
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Visualizar'"
                                        icon="pi pi-external-link"
                                        @click="$router.push(`/transferencias/salvar/${ props.data.id }/`)"
                                    )

                Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

                Panel.datatable(header='Lista de Contas a Receber')
                    DataTable(:value="list")
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_vencimento_f' header='Data de Vencimento')
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_liquidacao_f' header='Data de Pagamento')
                        Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='ds_descricao' header='Descrição')
                        Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='credor' header='Credor')
                        Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='forma_pagamento' header='Forma de Pagamento')
                        Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='vl_titulo_f' header='Valor')
                            template(#body='props')
                                .ta-center
                                    p {{ props.data.vl_titulo_f }}
                                    p ( {{ props.data.parcela_atual }} / {{ props.data.qt_total_parcela }} )
                        Column(headerStyle='width: 15%;' bodyStyle='padding: 1px; height: 1px; text-align: center;' field='situacao' header='Situação')
                            template(#body='props')
                                span(:style="{'background-color': props.data.ds_situacao_cor}") <b>{{ props.data.ds_situacao_f }}</b> <br />
                        Column(headerStyle='width: 10%; text-align: center')
                            template(#header) Ações
                            template(#body='props')
                                .ta-center
                                    Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                        v-if='props.data.situacao != "PG"'
                                        v-tooltip.top="'Pagar'"
                                        icon="jam jam-coin"
                                        @click="handlePagarDialog(props.data)"
                                    )
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Visualizar'"
                                        icon="pi pi-external-link"
                                        @click="$router.push(`/contas-receber/salvar/${ props.data.cd_titulo_receber_regra }/`)"
                                    )
                                    Button.p-button-raised.p-button-rounded.p-button-danger(
                                        v-tooltip.top="'Cancelar'"
                                        v-if='props.data.situacao != "PG"'
                                        icon="jam jam-close-circle-f"
                                        @click="handleCancelarParcela(props.data)"
                                    )
                    Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
        div(v-if="telaVisivel == 'FA'")
            Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
                .p-grid.p-fluid
                    .p-col-12.p-md-3
                        label.form-label Credor:
                        .p-inputgroup
                            Dropdown(
                                placeholder='Selecione o credor...'
                                :options='options.tipoOption'
                                optionLabel='label'
                                optionValue='value'
                                @change='() => {applyFilters();}'
                                v-model='filtersFaturas.ie_tipo'
                                filter
                            )

                    .p-col-12.p-md-3
                        label.form-label Situação:
                        ProgressBar(v-if='loading.situacao' mode="indeterminate")
                        .p-inputgroup(v-else)
                            Dropdown(
                                placeholder='Selecione situação...'
                                :options='options.situacaoFatura'
                                optionLabel='label'
                                optionValue='value'
                                @change='applyFilters()'
                                v-model='filtersFaturas.situacao'
                                filter
                            )
                    .p-col-12.p-md-3
                        label.form-label Data Inicial:
                        .p-inputgroup
                            Calendar( v-model='filtersFaturas.dt_inicial' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                            Button(icon='jam jam-rubber' @click="() => {filtersFaturas.dt_inicial = null; applyFilters();}")

                    .p-col-12.p-md-3
                        label.form-label Data Final:
                        .p-inputgroup
                            Calendar( v-model='filtersFaturas.dt_final' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                            Button(icon='jam jam-rubber' @click="() => {filtersFaturas.dt_final = null; applyFilters();}")

                    .p-col-3.p-md-3.ta-right
                        Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')
            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
            div(v-if='listFaturas.length == 0')
                p Nenhuma fatura encontrada
            Panel.datatable(v-else header='Lista de Faturas')
                DataTable(:value="listFaturas")
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_fatura_f' header='Data de Vencimento')
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='ds_descricao' header='Descrição')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor_f' header='Valor')
                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor_debito_credito' header='Valor Crédito / Débito')
                    Column(headerStyle='width: 15%;' bodyStyle='padding: 1px; height: 1px; text-align: center;' field='ie_situacao' header='Situação')
                        template(#body='props')
                            .cell(:style="{'background-color': props.data.ds_situacao_cor}")
                                span <b>{{ props.data.ds_situacao_f }}</b> <br />
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-success.p-button-raised.p-button-rounded.mr-1(
                                    v-if='props.data.ie_situacao != "PG"'
                                    v-tooltip.top="'Pagar'"
                                    icon="jam jam-coin"
                                    @click='handlePagarFaturaDialog(props.data)'

                                )
                                //- Button.p-button-raised.p-button-rounded.mr-1(
                                //-     v-tooltip.top="'Visualizar'"
                                //-     icon="pi pi-external-link"

                                //- )
                                //- Button.p-button-raised.p-button-rounded.p-button-danger(
                                //-     v-tooltip.top="'Cancelar'"
                                //-     icon="jam jam-close-circle-f"

                                //- )
                Paginator(:rows='paginatorFatura.per_page' :first='(paginatorFatura.page - 1) * paginatorFatura.per_page' :totalRecords='paginatorFatura.count' @page="onPageFatura($event)")

</template>

<style lang="scss">
    .caixas-listar {
        .p-dropdown-panel {
            position: absolute;
            z-index: 1000;
        }
        .info-resta {
            font-size: 15px;
            font-weight: 700;
            color: #888;
        }
        .custom-svg-icon {
			background: url("../../assets/img/estorno.svg");
            top: 9.5px;
            left: 9px;
			height: 23px;
			width: 23px;
			color: white
		}
        .payment-box {
            position: relative;
            .p-button-success {
                .cursor-pointer {
                    cursor: not-allowed !important;
                }
            }
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
            .text-method {
                font-size: 18px;
                margin: 0;
                margin-top: 14px;
            }
            .text-value {
                font-size: 22px;
                font-weight: 700;
                margin: 0;
                margin-top: 10px;
            }
            .text-parcelas {
                font-size: 15px;
                margin-top: 14px;
            }
            .icon-box {
                width: 50px;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #312c66;
                i {
                    font-size: 20px;
                    color: #fff;
                }
            }
            .btn-remove-payment {
                display: inline-block;
                position: absolute;
                top: 2px;
                right: 2px;
                background-color: #c8c8c8;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 16px;
                border-radius: 50%;
                i {
                    font-size: 20px;
                    color: #666;
                }
            }
        }
        .dialogRemover,
        .dialogAdicionar{
            width: 96%;
            max-width: 500px;
            .p-dialog-content {
                overflow: visible !important;
            }
        }
        .dialogCancelar {
            width: 20%;
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dialogAdicionarBaixar {
            width: 98%;
            max-width: 750px;
            .p-dialog-content {
                overflow: scroll !important;
            }
        }
        .custom-svg-icon-1 {
			background: url("../../assets/img/estorno.svg");
			top: 9.5px;
            left: 10px;
			height: 23px;
			width: 23px;
			color: white
		}
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .p-input-icon-left,
        .p-input-icon-right {
            position: relative;
            display: inline-block;
            width: 100%;
        }
        .p-input-icon-left > i,
        .p-input-icon-right > i {
            position: absolute;
            top: 50%;
            margin-top: -0.5rem;
            cursor: pointer;
        }
        .p-input-icon-left > i:first-of-type {
            left: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-right > i:last-of-type {
            right: 0.5rem;
            color: #6c757d;
        }
        .p-input-icon-left > .p-inputtext {
            padding-left: 2rem;
        }
        .p-input-icon-right > .p-inputtext {
            padding-right: 2rem;
        }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .info-container {
            border: 1px solid #CACACA;
            border-radius: 5px;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            margin-right: 10px;
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                height: 100%;
                align-content: center;
                align-items: center;
                text-align: center;
            }
        }

        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import InputNumber from 'primevue/inputnumber'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import Calendar from 'primevue/calendar'
    import SelectButton from 'primevue/selectbutton'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'

    import Card from "primevue/card";

    import moment from 'moment'

    import { pCalendarLocale_ptbr, processDominiosResponse } from './../../utils'
    import { ContasReceber, DominioValor, Caixas, ContasBancarias } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    export default {
        created () {
        Promise.all([
            this.getDominios(),
            this.getFormasPagamento(),
            this.getContaBancaria()
        ]).then(() => {
            this.applyFilters();
            this.getListFaturas();
        });
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputNumber, SelectButton, Card,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, Dropdown, Calendar, TabView, TabPanel},
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                listFaturas: [],
                telaVisivel: 'CR',
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingApagar: false,
                waitingUnidades: false,
                waitingComprovanteBaixa: false,
                waitingBaixarParcela: false,
                waitingParcela: false,
                waitingCancelarParcela: false,
                dialogApagar: false,
                dialogApagar_data: {},
                dominios: null,
                ptbr: pCalendarLocale_ptbr,
                info: {
                    contas_pagas: 0,
                    contas_vencer: 0,
                    contas_vencidas: 0,
                    total: 0
                },
                filters: {
                    ie_tipo: null,
                    situacao: null,
                    cd_destino: null,
                    cd_plano_contas: null,
                    dt_inicial: null,
                    dt_final: null,
                },
                filtersFaturas: {
                    ie_tipo: null,
                    situacao: null,
                    cd_destino: null,
                    cd_plano_contas: null,
                    dt_inicial: null,
                    dt_final: null,
                },
                options:{
					situacao: [],
                    situacaoFatura: [],
                    ie_tipo: [],
                    formasPagamento: [],
                    contas: [],
                    tipo_baixa: [],
                    situacao_baixa: []
                },
                dominio: {
                    tipo: [],
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                paginatorFatura: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                loading: {
					situacao: false,
					destino: false,
				},
                dialogBaixa: {
                    visible: false,
                    data: null
                },
                dialogBaixaFatura: {
                    visible: false,
                    data: null
                },
                dialogCancelar: {
                    visible: false,
                    data: null
                },
                dialog: {
                    parcela: false,
                    pagar: false,
                    formaPagamento: false,
                },
                parcelaModel: {
                    dt_vencimento: '',
                    vr_parcela: null,
                },
                formaPagamentoModel: {
                    cd_forma_pagamento: null,
                    vl_pago: null,
                    cd_conta_recebimento: null,
                },
                baixaFaturaModel: {
                    dt_baixa: null,
                    vl_baixa: 0,
                    vl_pago: 0,
                    vl_multa: 0,
                    vl_juros: 0,
                    vl_acrescimo: 0,
                    vl_desconto: 0,
                    vl_ir: 0,
                    vl_iss: 0,
                    vl_devolucao: 0
                },
                baixaModel : {
                    cd_titulo_receber: null,
                    cd_banco: null,
                    dt_baixa: null,
                    cd_tipo_baixa: 'BM',
                    ie_situacao: 'PA',
                    vl_baixa: 0,
                    vl_multa: 0,
                    vl_juros: 0,
                    vl_impostos_totais: 0,
                    vl_desconto: 0,
                    vl_pago: 0,
                    formas_pagamento: []
                },
                comprovanteBaixa: {},
                formasPagamentoList: [],
            }
        },
        computed: {
            resta() {
                var totalNovosPagamentos =
                    this.formasPagamentoList.length > 0
                    ? this.formasPagamentoList.reduce(
                        (accumalator, currentValue) =>
                            accumalator + currentValue.vl_pago,
                        0
                        )
                    : 0;
                var valorPagar = +this.baixaModel.vl_baixa + this.baixaModel.vl_impostos_totais + this.baixaModel.vl_juros + this.baixaModel.vl_multa

                if(this.baixaModel.vl_desconto > 0)
                    valorPagar -= this.baixaModel.vl_desconto
                return parseFloat(valorPagar - totalNovosPagamentos);
            },
        },
        methods: {
            resetFilters() {
				this.filters.ie_tipo = null
				this.filters.cd_origem = null
                this.filters.cd_destino = null
				this.filters.cd_plano_contas = null
				this.filters.dt_inicial = null
				this.filters.dt_final = null
			},
			limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
            getList (params) {
                this.waiting = true
                return ContasReceber.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.data.count
                        this.info.contas_pagas = response.data.contas_pagas.total
                        this.info.contas_vencer = response.data.contas_vencer.total
                        this.info.contas_vencidas = response.data.contas_vencidas.total
                        this.info.total = response.data.total.total
                        response.data.data.results.forEach(item => {
                            item.ds_situacao_cor = this.getSituacao(item.situacao)?.['ds_cor'];
                            item.ds_situacao_f = this.getSituacao(item.situacao)?.['ds_valor']
                            item.vl_titulo_f = this.formatPrice(item.vl_titulo)
                            item.dt_vencimento_f = item.dt_vencimento ?  moment(item.dt_vencimento).format('DD/MM/YYYY') : ''
                            item.dt_liquidacao_f = item.dt_liquidacao ? moment(item.dt_liquidacao).format('DD/MM/YYYY') : ''
                        })
                        this.list = response.data.data.results

                    }
                    this.waiting = false
                    return true
                })
            },
            applyFiltersFaturas () {
                let params = {
                     paginacao: true,
                     page: this.paginator.page,
                     per_page: this.paginator.per_page
                     }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filtersFaturas).forEach((key) => {
                    if ((this.filters[key])) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filtersFaturas[key]).utcOffset('-03:00').format('YYYY-MM-DD')
                        else params[key] = this.filtersFaturas[key]
                    }
                })
                this.getListFaturas(params)
            },
            getListFaturas (params) {
                this.waiting = true
                return ContasReceber.findFaturas(params).then(response => {
                    if (response.status == 200) {
                        console.log(response)
                        response.data.forEach(item => {
                            item.ds_situacao_cor = this.getSituacaoFatura(item.ie_situacao)?.['ds_cor'];
                            item.ds_situacao_f = this.getSituacaoFatura(item.ie_situacao)?.['ds_valor']
                            item.nr_valor_f = this.formatPrice(item.nr_valor)
                            item.dt_fatura_f = item.dt_fatura ?  moment(item.dt_fatura).format('DD/MM/YYYY') : ''
                            item.dt_baixa_f = item.dt_baixa ? moment(item.dt_baixa).format('DD/MM/YYYY') : ''
                        })
                        this.listFaturas = response.data
                    }
                    this.waiting = false
                    return true
                })
            },
            async getFormasPagamento() {
                await Caixas.getFormasPagamento().then((response) => {
                    if (response.status == 200) {
                        response.data.forEach((elem) => {
                            this.options.formasPagamento.push({
                                value: elem.id,
                                label: elem.nm_forma_pagamento,
                            });
                        });
                    }
                });
            },
            async getDominios () {
                this.loading.situacao = true
                await DominioValor.findAll({ds_mnemonico: ['SITUACAO_CONTAS_RECEBER', 'ADIANTAMENTO_TIPO','TIPO_PESSOA', 'FREQUENCIA', 'CODIGOS_TIPO_BAIXA', 'SITUACAO_TITULO_BAIXA', 'SITUACAO_FATURA']}).then((response) => {
                    this.dominios = processDominiosResponse(response)
					if(response.status === 200) {
                        response.data['SITUACAO_CONTAS_RECEBER'].valores.forEach(item => {
							this.options.situacao.push({
                                label: item.ds_valor,
                                value: item.ie_valor,
                                ds_cor: item.ds_cor
                                })
                        })
                        response.data['SITUACAO_FATURA'].valores.forEach(item => {
							this.options.situacaoFatura.push({
                                label: item.ds_valor,
                                value: item.ie_valor,
                                })
                        })
                        response.data['ADIANTAMENTO_TIPO'].valores.forEach(item => {
							this.options.ie_tipo.push({label: item.ds_valor, value: item.ie_valor})
						})
                        response.data['TIPO_PESSOA'].valores.forEach(item => {
							this.options.ie_tipo.push({label: item.ds_valor, value: item.ie_valor})
						})
                        response.data['CODIGOS_TIPO_BAIXA'].valores.forEach(item => {
							this.options.tipo_baixa.push({label: item.ds_valor, value: item.ie_valor})
						})

                        this.loading.situacao = false
					}
				})
            },
            async getContaBancaria(){
                await ContasBancarias.findAll().then((response) => {
                    if (response.status == 200) {
                        response.data.forEach((elem) => {
                            this.options.contas.push({
                                value: elem.id,
                                label: `${elem.nr_agencia} / ${elem.nr_conta} / ${elem.nm_banco}`,
                            });
                        });
                    }
                });
            },
            getSituacao(ie_valor) {
                return this.dominios['SITUACAO_CONTAS_RECEBER']?.[ie_valor];
            },
            getSituacaoFatura(ie_valor) {
                return this.dominios['SITUACAO_FATURA']?.[ie_valor];
            },
            formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            applyFilters () {
                let params = {
                     paginacao: true,
                     page: this.paginator.page,
                     per_page: this.paginator.per_page
                     }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if ((this.filters[key])) {
                        if (key.substr(0, 3) == 'dt_')
                            params[key] = moment(this.filters[key]).utcOffset('-03:00').format('YYYY-MM-DD')
                        else params[key] = this.filters[key]
                    }
                })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onPageFatura (ev) {
                this.paginatorFatura.page = ev.page + 1
                this.getListFaturas()
            },
            remove () {
                this.waitingApagar = true
                ContasReceber.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Transferência cancelada com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            handleParcelaDialog(data) {
                this.parcelaModel.dt_vencimento = data.dt_vencimento
                this.parcelaModel.vl_titulo = data.vl_titulo
                this.dialog.parcela = true
            },
            handlePagarDialog(data) {
                this.dialogBaixa.data = data
                this.baixaModel.vl_baixa = data.vl_titulo
                this.dialogBaixa.visible = true
            },
            handlePagarFaturaDialog(data) {
                this.dialogBaixaFatura.data = data
                this.baixaFaturaModel.vl_baixa = data.nr_valor_f
                this.dialogBaixaFatura.visible = true
            },
            handleFormaPagamentoDialog() {
                this.formaPagamentoModel = {
                    cd_forma_pagamento: null,
                    vl_pago: null,
                    cd_conta_recebimento: null,
                },
                this.dialog.formaPagamento = true
            },
            handleCancelarParcela(data) {
                this.dialogCancelar.data = data
                this.dialogCancelar.visible = true
            },
            adicionarFormaPagamento() {
                var a = {
                    cd_forma_pagamento: this.formaPagamentoModel.cd_forma_pagamento,
                    cd_forma_pagamento_f: this.options.formasPagamento.find(i => i.value === this.formaPagamentoModel.cd_forma_pagamento).label,
                    vl_pago: this.formaPagamentoModel.vl_pago,
                    vl_pago_f: this.$root.formatPrice(this.formaPagamentoModel.vl_pago),
                    cd_conta_recebimento_f: this.options.contas.find(i => i.value === this.formaPagamentoModel.cd_conta_recebimento).label,
                    cd_conta_recebimento: this.formaPagamentoModel.cd_conta_recebimento
                }
                this.formasPagamentoList.push(a)
                this.dialog.formaPagamento = false
            },
            baixarParcela() {

                if(this.baixaModel.dt_baixa == null) {
                    this.$toast.warning(`Insira uma data de recebimento`)
                    return
                }

                if(this.resta != 0) {
                    this.$toast.warning(`Ainda resta R$ ${this.resta} para completar a baixa.`)
                    return
                }

                if(this.baixaModel.dt_baixa == null) {
                    this.$toast.warning(`Insira uma data de recebimento`)
                    return
                }
                this.waitingBaixarParcela = true;

                let dataSend = {
                    cd_titulo_receber: this.dialogBaixa.data.id,
                    cd_banco: this.baixaModel.cd_banco,
                    dt_baixa: moment(this.baixaModel.dt_baixa).format("YYYY-MM-DD"),
                    cd_tipo_baixa: this.baixaModel.cd_tipo_baixa,
                    ie_situacao: this.baixaModel.ie_situacao,
                    vl_baixa: this.baixaModel.vl_baixa,
                    vl_multa: this.baixaModel.vl_multa,
                    vl_juros: this.baixaModel.vl_juros,
                    vl_desconto: this.baixaModel.vl_desconto,
                    vl_pago: this.baixaModel.vl_pago,
                    formas_pagamento: this.formasPagamentoList,
                    nm_usuario_cri: 'manassess'
                }

                ContasReceber.baixaParcela(dataSend.cd_titulo_receber, dataSend).then((response) => {
                    this.waitingBaixarParcela = false;
                    if (response.status === 200) {
                        this.$toast.success("Baixa realizada com sucesso.");
                        this.dialogBaixa.visible = false
                        location.reload()
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao realizar a baixa.");
                    }
                });
            },
            baixarFatura() {
                if(this.baixaFaturaModel.dt_baixa == null) {
                    this.$toast.warning(`Insira uma data de recebimento`)
                    return
                }

                this.waitingBaixarParcela = true;

                let dataSend = {
                    dt_baixa: moment(this.baixaFaturaModel.dt_baixa).format("YYYY-MM-DD"),
                    vl_baixa: this.dialogBaixaFatura.data.nr_valor,
                    vl_multa: this.baixaFaturaModel.vl_multa,
                    vl_juros: this.baixaFaturaModel.vl_juros,
                    vl_desconto: this.baixaFaturaModel.vl_desconto,
                    vl_pago: this.baixaFaturaModel.vl_pago,
                    vl_ir: this.baixaFaturaModel.vl_ir,
                    vl_iss: this.baixaFaturaModel.vl_iss,
                    vl_acrescimo: this.baixaFaturaModel.vl_acrescimo,
                }

                console.log("ID:", this.dialogBaixaFatura)
                ContasReceber.baixaFatura(this.dialogBaixaFatura.data.id, dataSend).then((response) => {
                    this.waitingBaixarParcela = false;
                    if (response.status === 200) {
                        this.$toast.success("Baixa realizada com sucesso.");
                        this.dialogBaixa.visible = false
                        location.reload()
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao realizar a baixa.");
                    }
                });
            },
            cancelarParcela() {
                this.waitingCancelarParcela = true
                ContasReceber.cancelarParcela(this.dialogCancelar.data.id).then(response => {
                    if([200, 201, 204].includes(response.status)) {
                        this.$toast.success("Parcela cancelada com sucesso");
                        this.dialogCancelar.visible = false
                        location.reload()
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                        this.waitingCancelarParcela = false
                    } else {
                        this.toastResponseDetail("Falha ao cancelar a parcela.");
                        this.waitingCancelarParcela = false
                    }
                })
            },
            anexarComprovanteBaixa() {
                const files = this.$refs.comprovanteBaixa.files;
                const comp = files.length && files[0];
                if (!comp) return;

                let cd_titulo = this.dialogBaixa.data.id

                let dataSend = {
                    aq_comprovante_pagamento: comp,
                    cd_titulo: cd_titulo,
                };

                this.waitingComprovanteBaixa = true;
                ContasReceber.anexarComprovante(dataSend).then((response) => {
                    this.waitingComprovanteBaixa = false;
                    if (response.status === 200) {
                        this.comprovanteBaixa = files[0];
                        this.$toast.success("Comprovante anexado com sucesso.");
                    } else if (response.data.detail) {
                        this.toastResponseDetail(response.data.detail);
                    } else {
                        this.toastResponseDetail("Falha ao enviar comprovante.");
                    }
                });
            },
            onTabChange (ev) {
                this.telaVisivel = ev.tab.$attrs.telaVisivel
                if(this.telaVisivel == "CR")
                    // this.getList({ 'rede_medclub_empresa': true })
                    this.applyFilters()
            },

        }
    }
</script>
